import { Skeleton, Container, Stack } from "@mui/material";

import PostCardSkeleton from "./PostCard";

const TeamPageSkeleton = () => {
  return (
    <>
      <Container
        component="section"
        disableGutters
        maxWidth={false}
        className="justify-center pt-8 [&>*]:mx-auto [&>*]:max-w-[95%]"
      >
        <Stack className="w-full">
          <Skeleton
            width="100%"
            height={"auto"}
            sx={{ aspectRatio: "1.8" }}
            variant="rectangular"
          />
        </Stack>
      </Container>
      <Stack className="mt-14 h-[186px] w-full">
        <Skeleton width="100%" height="100%" variant="rectangular" />
      </Stack>
      <Container
        component="section"
        disableGutters
        maxWidth={false}
        className="mb-28 justify-center pt-8 [&>*]:mx-auto [&>*]:max-w-[95%]"
      >
        <Stack className="mb-10 w-full flex-row flex-wrap justify-between">
          {Array(3)
            .fill([1, 1, 1])
            ?.slice(0, 3)
            .map((_, index) => (
              <Stack key={index} className="w-[32%]">
                <PostCardSkeleton type="video" featured full />
              </Stack>
            ))}
        </Stack>
        <Stack className="mb-40 w-full flex-row justify-between">
          <Stack className="w-[28%]" rowGap={7}>
            <Skeleton variant="rectangular" width={300} height={600} />
            <Stack className="w-full" rowGap={2}>
              <Skeleton variant="text" className="text-center"></Skeleton>

              <Skeleton variant="rectangular" width="100%" height="400px" />
            </Stack>
          </Stack>
          <Stack className="w-[43%] items-center" rowGap={2}>
            <Skeleton
              variant="rectangular"
              className="text-center"
              width="100%"
              height="20px"
            />

            <Stack width="100%" className="mt-2">
              {Array(6)
                .fill([1, 2, 3, 4])
                .map((_, index) => (
                  <Stack rowGap={2} key={index} className="w-full">
                    <Stack className="my-4 w-full">
                      <PostCardSkeleton type="image" full />
                    </Stack>
                  </Stack>
                ))}
            </Stack>
          </Stack>
          <Stack className="w-[350px] items-center" rowGap={3}>
            <Skeleton className="h-[600px] w-[300px]" variant="rectangular" />
            <Stack className="w-full items-center justify-center">
              <Skeleton width="300px" height="450px" variant="rounded" />
            </Stack>
            <Skeleton className="h-[600px] w-[300px]" variant="rectangular" />
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default TeamPageSkeleton;
