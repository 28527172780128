import React from "react";

import { NextPage } from "next";
import dynamic from "next/dynamic";

import { sizeMaps } from "lib/dfp";
import { ErrorBoundary } from "ui/src/ErrorHandling";

import TeamPageSkeleton from "@/components/skeletons/TeamPage";
import Page from "@/layouts/BasePage";

import type { SiteProps } from "utils/types";
import type { AdSlotsMap } from "utils/types/ads";
import type { ImagePost, TeamMedia, VideoPost } from "utils/types/content";

const TeamInfoPage = dynamic(() => import("@/components/Team"), {
  ssr: false,
  loading: () => <TeamPageSkeleton />,
});

const ComingSoon = dynamic(() => import("@/components/Team/ComingSoon"), {
  ssr: false,
});

interface Props extends SiteProps {
  imagePosts: ImagePost[];
  videoPosts: VideoPost[];
  teamMedia: Array<TeamMedia>;
}

const getTeamAds = (teamName: string): AdSlotsMap => ({
  dleaderboard: {
    adUnit: `/TPL_${teamName}_TeamHome_Desk_Leaderboard_728Flex`,
    size: sizeMaps.leaderboard,
    divId: "div-gpt-ad-1696194136026-0",
  },
  dflex1: {
    adUnit: `/TPL_${teamName}_TeamHome_Desk_MPU_300_1`,
    size: sizeMaps.flexBoxLarge,
    divId: "div-gpt-ad-1696194215388-0",
  },
  dflex2: {
    adUnit: `/TPL_${teamName}_TeamHome_Desk_MPU_300_2`,
    size: sizeMaps.flexBoxLarge,
    divId: "div-gpt-ad-1696194315675-0",
  },
  dflex3: {
    adUnit: `/TPL_${teamName}_TeamHome_Desk_MPU_300_3-Left`,
    size: sizeMaps.flexBoxLarge,
    divId: "div-gpt-ad-1696194349012-0",
  },
  mleaderboard: {
    adUnit: `/TPL_${teamName}_TeamHome_Mobile_Leaderboard_320`,
    size: sizeMaps.mobile,
    divId: "div-gpt-ad-1696194409766-0",
  },
  mflex1: {
    adUnit: `/TPL_${teamName}_TeamHome_Mobile_MPU_300_1`,
    size: sizeMaps.mobile,
    divId: "div-gpt-ad-1696194488204-0",
  },
  mflex2: {
    adUnit: `/TPL_${teamName}_TeamHome_Mobile_MPU_300_2`,
    size: sizeMaps.mobile,
    divId: "div-gpt-ad-1696194524904-0",
  },
});

const TeamPage: NextPage<Props> = ({
  imagePosts,
  videoPosts,
  team,
  teamMedia,
  ...props
}) => {
  return (
    <>
      {!imagePosts.length && !videoPosts.length ? (
        <Page
          team={team}
          metadata={{ title: `${team.shortName} Coming Soon!` }}
          hideBreadCrumbs
          {...props}
        >
          <ErrorBoundary>
            <ComingSoon team={team} />
          </ErrorBoundary>
        </Page>
      ) : (
        <Page
          team={team}
          metadata={{ title: team.shortName }}
          {...props}
          hideBreadCrumbs
        >
          <ErrorBoundary>
            <TeamInfoPage
              team={team}
              videoPosts={videoPosts}
              imagePosts={imagePosts}
              teamMedia={teamMedia ? teamMedia[0] : null}
              adSlots={getTeamAds(team.shortName)}
            />
          </ErrorBoundary>
        </Page>
      )}
    </>
  );
};

export async function getStaticProps({ params }) {
  const { fetchSiteProps } = await import("@/utils/fetchers");
  const { team } = params;

  const siteProps = await fetchSiteProps(team as string);

  const { getContentService } = await import("api/content-service");

  if (siteProps.team) {
    const [{ imagePosts, videoPosts }, { teamPagesMedias }] = await Promise.all(
      [
        getContentService()
          .posts(siteProps.team.shortName)
          .getHighlightedPosts(),
        getContentService().teamPage(siteProps.team.shortName).getTeamMedia(),
      ]
    );

    return {
      props: {
        imagePosts,
        videoPosts,
        teamMedia: teamPagesMedias,
        ...siteProps,
      },
      revalidate: 60,
    };
  }

  return { notFound: true };
}

export async function getStaticPaths() {
  const { getSiteService } = await import("api/site-service");
  const allTeams = await getSiteService().teams().fetchAll();

  return {
    paths:
      allTeams.map((team) => ({
        params: { team: team.shortName.toLowerCase() },
      })) ?? [],
    fallback: "blocking",
  };
}

export default TeamPage;
